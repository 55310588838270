import { useReducer , useEffect, createContext } from 'react';
import PropTypes from 'prop-types';

const INIT_STATE = {
    address:null,
    factoryContract:null
};



const reducer = (state: any,action: any) => {
    switch(action.type){
        case 'getAddress':
            return { ...state , address: action.payload };
        case 'setfactoryContract':
            return { ...state , factoryContract: action.payload };
        
        default:
            throw new Error(`Unknown type: ${action.type}`);
    }
}

const StoreContext = createContext<any>(null);

const StoreContextProvider = (props:any) => {
    // filtering props and merge with default param value
    
    const initState = { ...INIT_STATE }; 
    const [state, dispatch] = useReducer(reducer, initState);
  
    useEffect(()=>{
    },[])
  
    return <StoreContext.Provider value={{ state, dispatch }}>{props.children}</StoreContext.Provider>
};

StoreContextProvider.propTypes = {
    address: PropTypes.string,
    factoryContract: PropTypes.object
};

export { StoreContextProvider , StoreContext };
