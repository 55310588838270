import React, { useEffect } from 'react';
import Hedaer from './component/Hedaer';
import { Outlet } from 'react-router-dom';
import { StoreContextProvider } from './store/ContextProvider';
import { createWeb3Modal,   defaultConfig } from '@web3modal/ethers/react'
import { Toaster } from 'react-hot-toast';
import Footer from './component/Footer';
import { chaninArr } from './util/config';

const projectId = '91e41d1a9136fdeec6a7e53659048ab8'

const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  defaultChain:chaninArr[2],
  chains: chaninArr,
  projectId
})

function App() {
  return (
      <StoreContextProvider>
        <Toaster toastOptions={{duration:1500}} />
        <Hedaer />
        <div className='padding_con'>
          <Outlet />
        </div>
        <Footer />
      </StoreContextProvider>
  );
}

export default App;
