import { useEffect, useState } from 'react';
import btcImg from '../assets/img/btc.png'
import logoImg from '../assets/img/logo1.png'
import box3 from '../assets/img/box3.png'
import box4 from '../assets/img/box4.png'
import bgr from '../assets/img/bgr.png'
import box5 from '../assets/img/box5.png'
import logo2Img from '../assets/img/logo2.png'
import logo3Img from '../assets/img/logo3.png'
import seerImg from '../assets/img/seer.png'
import tgImg from '../assets/img/tg.png'
import box7 from '../assets/img/box7.png'
import icon7 from '../assets/img/icon7.png'
import bg9 from '../assets/img/bg9.png'
import icon1 from '../assets/img/icon1.png'
import icon2 from '../assets/img/icon2.png'
import icon3 from '../assets/img/icon3.png'
import icon4 from '../assets/img/icon4.png'
import bgs from '../assets/img/bgj.png'
import KAI from '../assets/img/KAI.png'
import missweb3 from '../assets/img/missweb3.png'
import romani from '../assets/img/romani.png'

import CountUp from 'react-countup';
import { Statistic } from 'antd';

import '../assets/css/home.less'

const HomeView = () => {


  useEffect(() => {
  }, [])



  return <div className='homeBox'>
    <div className='home_box1 content'>

      <h3>Harnessing Bitcoin's <br /> Potential To Forge The <br /> Future Of The<br /> Decentralized Economy</h3>
      <p>A New Era for Trustless Bitcoin Staking</p>
      <button>Stake Now</button>
      <button className='w_btn'><a href="/Roman_WhitePaper(1.0).pdf" download="WhitePaper" >White Paper</a></button>
      
      <img src={btcImg} alt="" className='element' />
    </div>

    <div className='home_box2'>
      <div className='content'>
        <dl>
          <dt>Total Staked Tokens</dt>
          <dd><Statistic prefix='$' value={112893.51} formatter={() => <CountUp end={112893} separator="," />} /></dd>
        </dl>
        <dl>
          <dt>Total Rewards Paid</dt>
          <dd><Statistic prefix='$' value={314543400} formatter={() => <CountUp end={314543400} separator="," />} /></dd>
        </dl>
        <dl>
          <dt>Stakers</dt>
          <dd><Statistic prefix='$' value={43555} formatter={() => <CountUp end={43555} separator="," />} /></dd>
        </dl>
      </div>
    </div>

    <div className='home_box3'>
      <div className='content'>
        <div className='tit'>
          <h3>Supported Networks</h3>
          <p>Roman lets you stake tokens from many networks. Choose a network below to get started.</p>
        </div>
        <ul>
          <li>
            <div className='box3_l'>
              <img src={logoImg} alt="" />
              <h3>ROMAN</h3>
              <p>Stake any amount of BTC, get daily staking rewards and participate in DEFI in the Roma Ecosystem.</p>
              <div className='flex'>
                <div>
                  <h4><i>27%</i></h4>
                  <span>APR</span>
                </div>
                <div>
                  <h4>$ 39,432,423,000</h4>
                  <span>Stakeed</span>
                </div>
              </div>
              <div className='flex_sb'>
                <button>Learn More</button>
                <button>Learn More</button>
              </div>
            </div>
          </li>
          <li>
            <div className='box3_r'>
              <div><img src={box3} alt="" /></div>
              <div className='box3bgc'>
                <h3>Multiple Chains Are Coming Soon</h3>
                <button>Build With Roman</button>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>

    <div className='home_box4 content'>
      <div className='tit'>
        <h3>How Roman Works</h3>
      </div>

      <div className='box4_con'>
        <ul>
          <li>step1</li>
          <li>step2</li>
          <li>step3</li>
        </ul>
        <img src={box4} alt="" />
      </div>

      <ul className='ul'>
        <li>
          <h3>Stakers</h3>
          <p>Stake any amount of your tokens to <br /> access daily staking rewards</p>
          <div>
            <span>Stake</span>
            <span>Secure</span>
            <span>Earn</span>
          </div>
        </li>
        <li>
          <h3>Pos Chains</h3>
          <p>How many Bitcoin pledges，how many <br /> rewards</p>
          <div>
            <span>Secure</span>
            <span>Modularization</span>
            <span>Cross-Chain</span>
          </div>
        </li>
        <li>
          <h3>DA</h3>
          <p>An efficient and robust data <br /> availability layer</p>
          <div>
            <span>Compatibility</span>
            <span>Rollup</span>
            <span>Lower-Costs</span>
          </div>
        </li>
      </ul>

      {/* <img src={bgr} alt="" /> */}
    </div>

    <div className='home_box5'>
      <div className='content'>
        <div className='tit'>
          <h3>Roman DAO</h3>
          <p>The Roman DAO is a Decentralized Autonomous
            Organization that decides on the key upgrades and key parameters of staking protocols through the voting power of governance token (ROMA) tokens.</p>
        </div>

        <div className='box5_con'>
          <div>
            <img src={box5} alt="" />
            <h4>
              Governance process
              <p>Explore how the decisions in Roman governance process <br /> Are made.</p>
            </h4>
          </div>
          <ul>
            <li>
              <img src={logo2Img} alt="" />
              <div>
                <h3>Research Forum</h3>
                <p>Join the forum to find answers or discuss your ideas for Roman</p>
              </div>
            </li>
            <li>
              <img src={logo3Img} alt="" />
              <div>
                <h3>Key governance proposals</h3>
                <p>All key governance decisions and related Snapshots in one place</p>
              </div>
            </li>
            <li>
              <img src={logo2Img} alt="" />
              <div>
                <h3>LEGO</h3>
                <p>Explore how Roman Ecosystem Grants Organisation works</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className='home_box6 content'>
      <div className='box5_bg flex_sb'>
        <div className='flex'>
          <img src={tgImg} alt="" />
          <h3>
            Governance Notification Bot
            <p>Stay up to speed with Roman governance ongoing votings.</p>
          </h3>
        </div>

        <button>Get Notified</button>
      </div>
      <div className='tit'>
        <h3>Roman Ecosystem</h3>
        <p>Explore DAPPs and services within Roman ecosystem to get more benefits.</p>
      </div>

      <ul>
        <li>
          <img src={missweb3} alt="" />
          <div>
            <h3>Missweb3</h3>
            <p>Web3 Social Value Discovery Protocol | Make encounters more valuable</p>
          </div>
        </li>
        <li>
          <img src={KAI} alt="" />
          <div>
            <h3>KAI</h3>
            <p>Web3 Social Value Discovery Protocol | Make encounters more valuable</p>
          </div>
        </li>
        <li>
          <img src={romani} alt="" />
          <div>
            <h3>Romani</h3>
            <p>Web3 Social Value Discovery Protocol | Make encounters more valuable</p>
          </div>
        </li>
        {
          [...new Array(6)].map(() => {
            return <li>
              <img src={seerImg} alt="" />
              <div>
                <h3>Seer</h3>
                <p>Web3 Social Value Discovery Protocol | Make encounters more valuable</p>
              </div>
            </li>
          })
        }
      </ul>
    </div>

    <div className='home_box7'>
      <div className='content'>
        <div className='tit'>
          <h3>Validators</h3>
          <p>Explore validators that help grow Roman</p>
        </div>
        <img src={box7} alt="" />
        <div className='box7_con'>
          <img src={icon7} alt="" />
          <h2>Become a Node Operator</h2>
          <p>Find out about the requirements, expectations, and process for applying to be a Roman Node Operator</p>
          <button>Join Node Operator Set</button>
        </div>
      </div>
    </div>

    <div className='home_box8'>
      <div className='content'>
        <div className='tit'>
          <h3>Powering The Roman Network</h3>
          <p>The ROMA token serves three distinct purposes: <br /> Governance over the network, staking and bonding.</p>
        </div>

        <ul>
          <li>
            <img src={logo3Img} alt="" />
            <h3>Governance</h3>
            <p>Roman token holders have complete control over the protocol.
              All privileges, which on other platforms are exclusive to
              miners will be given to the  Chain participants (ROMA holders),
              including managing exceptional events such as protocol
              upgrades and fixes.</p>
          </li>
          <li>
            <img src={logo3Img} alt="" />
            <h3>Staking</h3>
            <p>Token holders keep the network secure and collect rewards by staking ROMA.
               Roman’s advanced staking system is designed for maximum decentralization 
               and fair representation, rewarding good actors and punishing bad actors to
                ensure network security.</p>
          </li>
          <li>
            <img src={logo3Img} alt="" />
            <h3>Bonding</h3>
            <p>New PoSchains are added by bonding tokens. 
              Outdated or non-useful PoSchains are removed by
               removing bonded tokens. This is a form of proof
                of stake.</p>
          </li>
        </ul>
      </div>
    </div>

    <div className='home_box9 content'>
      <div className='tit'>
        <h3>From the Blog</h3>
        <p>Learn more about Roman, technology, vision and validators</p>
      </div>
      <ul>
        <li>
          <div>
            <img src={bg9} alt="" />
            <h2>
              The huge <br /> business <br /> opportunities <br /> brought by BTC <br /> pledge
            </h2>
          </div>
          <dl>
            <dt>Roman Chain introduction</dt>
            <dd>March 3,2024</dd>
          </dl>
        </li>
        <li>
          <div>
            <img src={bg9} alt="" />
            <h2>
              Accelerating <br /> The Unification <br /> Of Web3
            </h2>
          </div>
          <dl>
            <dt>Roman is a Web3 infrastructure layer</dt>
            <dd>March 1,2024</dd>
          </dl>
        </li>
        <li>
          <div>
            <img src={bg9} alt="" />
            <h2>
              A Data<br /> Availability<br /> Layer<br /> Compatible With<br /> L2 Frameworks
            </h2>
          </div>
          <dl>
            <dt>Roman DA Layer</dt>
            <dd>March 19,2024</dd>
          </dl>
        </li>
      </ul>
    </div>

    <div className='home_box10'>
      <div className='content'>
        <div className='tit'>
          <h3>Join Community</h3>
          <p>Learn more about Roman and have your say in the future of the Roman ecosystem</p>
        </div>

        <img src={bgs} alt="" />

        <ul>
          <li>
            <img src={icon1} alt="" />
            <div>
              <h3>Telegram</h3>
              <p>Join chat</p>
            </div>
          </li>
          <li>
            <img src={icon2} alt="" />
            <div>
              <h3>X</h3>
              <p>Follow @Romanchain_io</p>
            </div>
          </li>
          <li>
            <img src={icon3} alt="" />
            <div>
              <h3>GitHub</h3>
              <p>Contribute</p>
            </div>

          </li>
          <li>
            <img src={icon4} alt="" />
            <div>
              <h3>Contact</h3>
              <p>Get in touch with info@roman.fi</p>
            </div>

          </li>

        </ul>
      </div>
    </div>




  </div>
}

export default HomeView;