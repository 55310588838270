import { useEffect } from "react"

const Home1 = () => {
  // const [objData, setobjData] = useState<any>(null)
  useEffect(() => {
    
  }, [])

  return <div className="Home1">
    Home1
  </div>
}

export default Home1;