// 开发环境
const developmentArr = [
    {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        rpcUrl: 'https://mainnet.infura.io/v3/d039103314584a379e33c21fbe89b6cb',
        explorerUrl: 'https://etherscan.io',
        logo:''
    },
    {
        chainId: 97,
        name: 'BNB Smart Chain Testnet',
        currency: 'tBNB',
        rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
        explorerUrl: 'https://testnet.bscscan.com',
        decimals: 18,
        hexId: '0x61',
        logo:''
    },
]

// 生产环境
const productArr = [
    {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://mainnet.infura.io/v3/d039103314584a379e33c21fbe89b6cb',
        logo:''
    },
    {
        chainId: 56,
        name: 'Binance Smart Chain Mainnet',
        currency: 'BNB',
        rpcUrl: 'https://bsc-dataseed.binance.org',
        explorerUrl: 'https://bscscan.com',
        decimals: 18,
        hexId: '0x38',
        logo:''
    },
]

export const chaninArr = (process.env.NODE_ENV === "development")?developmentArr:productArr