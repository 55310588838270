import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import menuImg from '../assets/img/menu.png';

import '../assets/css/head.less'
import { useWeb3Modal } from '@web3modal/ethers/react'
import { onAdd } from '../util/api';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

const Hedaer = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { open } = useWeb3Modal()
  const { address } = useWeb3ModalAccount()
  const [openPH, setOpenPH] = useState(false);

  const menuArr = [
    { to: '/', name: 'Home' },
    { to: '/Ecosystem', name: 'Ecosystem' },
    { to: 'https://scan.romanchain.com', name: 'Romascan' , href:true},
    { to: '/Developers', name: 'Developers' },
    { to: '/Community', name: 'Community' },
    { to: '/About', name: 'About' },
  ]


  useEffect(() => {
    if (address) {
      let addr = localStorage.getItem('address') || null
      if (addr) {
        if (addr !== address) {
          localStorage.setItem('address', address)
          navigate(0)
        }
      } else {
        localStorage.setItem('address', address)
      }
    }
  }, [address])

  const showDrawer = () => {
    setOpenPH(true);
  };

  const onClose = () => {
    setOpenPH(false);
  };

  const isups = (name: string) => {
    return (location.pathname === name)
  }


  return <div className='header_box'>
    <ScrollToTop></ScrollToTop>
    <div className='content'>
      <div className='flex_sb'>
        <Link to="/" ><img src={logo} alt="logo" /></Link>
        <div>
          
          <div className='web menu_con'>
            <ul>
              {
                menuArr.map((item, idx) => <li key={item.name}  className={`${item.name == 'Home'?'homes':''} ${isups(item.to)?'ups':''}` } >
                  <NavLink target={item.href?"_blank":""} className={({ isActive }) => { return (isActive && isups(item.to)) ? "ups" : ""; }} to={item.href?item.to:"/"}>{item.name == 'Home'?'':item.name}</NavLink>
                </li>)
              }
            </ul>
          </div>
        </div>

        <div>
          <div className='wallet_box'>
            <div onClick={() => open()} className='ava_btn' >
              {address ? <div className='avater'><i></i>{onAdd(address)}</div> : <div className='avater'><i></i>Connect Wallet</div>}
            </div>
          </div>
          <div className='phone_menu phone'>
            <img src={menuImg} alt="" onClick={showDrawer} />
          </div>
        </div>

        <Drawer placement="right" open={openPH} closable={false} onClose={onClose} width={360}>
          <div className='menu_phone_box'>
            <div className='menu_close' onClick={onClose}>
              ×
            </div>
            <div className='menu_con'>
              <ul onClick={onClose}>
                {
                  menuArr.map((item, idx) => <li key={item.name}>
                    <NavLink target={item.href?"_blank":""} className={({ isActive }) => { return (isActive && isups(item.to)) ? "ups" : ""; }} to={item.href?item.to:"/"}>{item.name}</NavLink>
                  </li>)
                }
              </ul>
            </div>
          </div>
        </Drawer>
      </div>
    </div>


  </div>
}

const ScrollToTop = (props: any) => {

  const { pathname } = useLocation();

  useEffect(() => {

    window.scrollTo(0, 0)

  }, [pathname]);

  return props.children;

}

export default Hedaer;

