import { useEffect } from "react"

const Home2 = () => {
  // const [objData, setobjData] = useState<any>(null)
  useEffect(() => {
    
  }, [])

  return <div className="Home2">
    Home2
  </div>
}

export default Home2;