// import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import './assets/font/font.css'
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import router from './router';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// console.log = (function (oriLogFunc) {
//   return function () {}
// })(console.log) 

root.render(
  // <React.StrictMode>
    // <App />
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
