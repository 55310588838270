import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
// import toast from 'react-hot-toast';
// import { useEffect } from 'react';
// import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

const Footer = () => {
  // const { address } = useWeb3ModalAccount()
  // const { walletProvider }:any = useWeb3ModalProvider()

  // useEffect(()=>{
  //   if(address){
  //     // getFactoryContract()
  //   }
  // },[address])
 

  return <div className='footer_box'>
      <div className='footer_div content'>
        <Link to="/" ><img src={logo} alt="logo" /></Link>
        <div>@2023 Romanchain.All rights reserved.</div>
      </div>
  </div>
}

export default Footer;