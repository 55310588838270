// import React from 'react';
import { createHashRouter } from "react-router-dom";
import App from './App';
import Home from './pages/Home';
import Home1 from "./pages/Home1";
import Home2 from "./pages/Home2";

const router = createHashRouter([
    {
        path: '/',
        element: <App />,
        children:[
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/home1',
                element: <Home1 />
            },
            {
                path: '/home2',
                element: <Home2 />
            }
        ]
    },
    
]);

export default router;
